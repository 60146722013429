import { useIntervalFn } from '@vueuse/core'
import { computed, isRef, onUnmounted, Ref, ref, watch } from 'vue'

export function useLogoRotation(enabled: Ref<boolean> | boolean = true) {
  const rotationInDegrees = ref(0)
  const styles = computed<Partial<CSSStyleDeclaration>>(() => {
    return {
      transform: `rotate(${rotationInDegrees.value}deg)`
    }
  })
  const { pause, resume } = useIntervalFn(() => {
    rotationInDegrees.value = rotationInDegrees.value + 60
  }, 518 + 180)

  watch(() => isRef(enabled) ? enabled.value : enabled, (enabled) => {
    if (enabled) {
      resume()
    } else {
      pause()
    }
  }, { immediate: true })

  onUnmounted(() => {
    pause()
  })

  return {
    styles
  }
}
