import * as HeadlessUI from '@headlessui/vue'
import DwHexDownloadFile from '@hexagon/ui/icons/dw-hex-download-file.vue'
import DwHexagonIcon from '@hexagon/ui/icons/dw-hexagon-icon.vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import { Plugin } from 'vue'
import DwAlert from '../alerts/dw-alert.vue'
import Dw2WayToggle from '../buttons/dw-2-way-toggle.vue'
import DwBtn from '../buttons/dw-btn.vue'
import DwFilterBtn from '../buttons/dw-filter-btn.vue'
import DwIndeterminateBtn from '../buttons/dw-indeterminate-btn.vue'
import DwNavBtn from '../buttons/dw-nav-btn.vue'
import DwChip from '../chips/dw-chip.vue'
import DwCollapse from '../elements/dw-collapse.vue'
import DwDataCard from '../elements/dw-data-card.vue'
import DwDocument from '../elements/dw-document.vue'
import DwNoDocument from '../elements/dw-no-document.vue'
import DwPageHeader from '../elements/dw-page-header.vue'
import DwCheckbox from '../form/dw-checkbox.vue'
import DwCombobox from '../form/dw-combobox.vue'
import DwInput from '../form/dw-input.vue'
import DwArrowIcon from '../icons/dw-arrow-icon.vue'
import DwArrowTopRightIcon from '../icons/dw-arrow-top-right-icon.vue'
import DwCartIcon from '../icons/dw-cart-icon.vue'
import DwDragHandleIcon from '../icons/dw-drag-handle-icon.vue'
import DwFileIcon from '../icons/dw-file-icon.vue'
import DwFilterIcon from '../icons/dw-filter-icon.vue'
import DwHammerIcon from '../icons/dw-hammer-icon.vue'
import DwI18nIcon from '../icons/dw-i18n-icon.vue'
import DwLoadingIndicator from '../icons/dw-loading-indicator.vue'
import DwLoadingIndicatorCircle from '../icons/dw-loading-indicator-circle.vue'
import DwMatIcon from '../icons/dw-mat-icon.vue'
import DwPicturePlusIcon from '../icons/dw-picture-plus-icon.vue'
import DwPlusOutlinedIcon from '../icons/dw-plus-outlined-icon.vue'
import DwRefreshIcon from '../icons/dw-refresh-icon.vue'
import DwTaskAddIcon from '../icons/dw-task-add-icon.vue'
import DwTooltipIcon from '../icons/dw-tooltip-icon.vue'
import DwTrashIcon from '../icons/dw-trash-icon.vue'
import DwUserIcon from '../icons/dw-user-icon.vue'
import DwUserLoginCircleIcon from '../icons/dw-user-login-circle-icon.vue'
import DwWaterMelonSeedsIcon from '../icons/dw-watermelon-seeds-icon.vue'
import DwContainer from '../layout/dw-container.vue'
import DwLinkOrSpan from '../links/dw-link-or-span.vue'
import DwLink from '../links/dw-link.vue'
import DwTertiaryNavigationItem from '../links/dw-tertiary-navigation-item.vue'
import DwModal from '../modal/dw-modal.vue'
import DwInfoModal from '../modal/dw-info-modal.vue'
import DwBreadcrumbs from '../navigation/dw-breadcrumbs.vue'
import DwText from '../layout/dw-text.vue'
import DwNotification from '../notifications/dw-notification.vue'
import DwProgressBar from '../progress/dw-progress-bar.vue'
import DwDataTable from '../tables/data-table/dw-data-table.vue'
import DwSimpleTable from '../tables/dw-simple-table.vue'
import DwFadeTransition from '../transitions/dw-fade-transition.vue'
import DwScaleTransition from '../transitions/dw-scale-transition.vue'
import DwSlideFadeTransition from '../transitions/dw-slide-fade-transition.vue'
import DwHidePasswordIcon from '@hexagon/ui/icons/dw-hide-password-icon.vue'
import DwShowPasswordIcon from '@hexagon/ui/icons/dw-show-password-icon.vue'
import DwSearchIcon from '@hexagon/ui/icons/dw-search-icon.vue'
import DwEditUserIcon from '@hexagon/ui/icons/dw-edit-user-icon.vue'
import DwAddUserIcon from '@hexagon/ui/icons/dw-add-user-icon.vue'
import DwThreeDotLoadingIndicator from '../loading/dw-three-dot-loading-indicator.vue'
import DwSortingDropdown from '../tables/dw-sorting-dropdown.vue'
import DwDotsMenuIcon from '@hexagon/ui/icons/dw-dots-menu-icon.vue'
import DwProalphaIcon from '@hexagon/ui/icons/dw-proalpha-icon.vue'
import DwClipboardIcon from '@hexagon/ui/icons/dw-clipboard-icon.vue'

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    DwAlert: typeof DwAlert
    DwArrowIcon: typeof DwArrowIcon
    DwArrowTopRightIcon: typeof DwArrowTopRightIcon
    DwBreadcrumbs: typeof DwBreadcrumbs
    DwBtn: typeof DwBtn
    DwCartIcon: typeof DwCartIcon
    DwCheckbox: typeof DwCheckbox
    DwChip: typeof DwChip
    DwClipboardIcon: typeof DwClipboardIcon
    DwCollapse: typeof DwCollapse
    DwCombobox: typeof DwCombobox
    DwContainer: typeof DwContainer
    DwDataCard: typeof DwDataCard
    DwDataTable: typeof DwDataTable
    DwDocument: typeof DwDocument
    DwDotsMenuIcon: typeof DwDotsMenuIcon
    DwDragHandleIcon: typeof DwDragHandleIcon
    DwEditUserIcon: typeof DwEditUserIcon
    DwFadeTransition: typeof DwFadeTransition
    DwFileIcon: typeof DwFileIcon
    DwFilterBtn: typeof DwFilterBtn
    DwFilterIcon: typeof DwFilterIcon
    DwHammerIcon: typeof DwHammerIcon
    DwHexDownloadFile: typeof DwHexDownloadFile
    DwHexIcon: typeof DwHexagonIcon
    DwI18nIcon: typeof DwI18nIcon
    DwIndeterminateBtn: typeof DwIndeterminateBtn
    DwInfoModal: typeof DwInfoModal
    DwInput: typeof DwInput
    DwLink: typeof DwLink
    DwLinkOrSpan: typeof DwLinkOrSpan
    DwLoadingIndicator: typeof DwLoadingIndicator
    DwLoadingIndicatorCircle: typeof DwLoadingIndicatorCircle
    DwMatIcon: typeof DwMatIcon
    DwModal: typeof DwModal
    DwNavBtn: typeof DwNavBtn
    DwNoDocument: typeof DwNoDocument
    DwNotification: typeof DwNotification
    DwPageHeader: typeof DwPageHeader
    DwPicturePlusIcon: typeof DwPicturePlusIcon
    DwPlusOutlinedIcon: typeof DwPlusOutlinedIcon
    DwProalphaIcon: typeof DwProalphaIcon
    DwProgressBar: typeof DwProgressBar
    DwRefreshIcon: typeof DwRefreshIcon
    DwScaleTransition: typeof DwScaleTransition
    DwSearchIcon: typeof DwSearchIcon
    DwSimpleTable: typeof DwSimpleTable
    DwSlideFadeTransition: typeof DwSlideFadeTransition
    DwSortingDropdown: typeof DwSortingDropdown
    DwTaskAddIcon: typeof DwTaskAddIcon
    DwTertiaryNavigationItem: typeof DwTertiaryNavigationItem
    DwText: typeof DwText
    DwThreeDotLoadingIndicator: typeof DwThreeDotLoadingIndicator
    DwTooltipIcon: typeof DwTooltipIcon
    DwTrashIcon: typeof DwTrashIcon
    DwUserAddIcon: typeof DwEditUserIcon
    DwUserIcon: typeof DwUserIcon
    DwUserLoginCircleIcon: typeof DwUserLoginCircleIcon
    DwWatermelonSeedsIcon: typeof DwWaterMelonSeedsIcon
    HCombobox: typeof HeadlessUI.Combobox
    HComboboxButton: typeof HeadlessUI.ComboboxButton
    HComboboxInput: typeof HeadlessUI.ComboboxInput
    HComboboxLabel: typeof HeadlessUI.ComboboxLabel
    HComboboxOption: typeof HeadlessUI.ComboboxOption
    HComboboxOptions: typeof HeadlessUI.ComboboxOptions
    HDialog: typeof HeadlessUI.Dialog
    HDialogBackdrop: typeof HeadlessUI.DialogBackdrop
    HDialogDescription: typeof HeadlessUI.DialogDescription
    HDialogOverlay: typeof HeadlessUI.DialogOverlay
    HDialogPanel: typeof HeadlessUI.DialogPanel
    HDialogTitle: typeof HeadlessUI.DialogTitle
    HDisclosure: typeof HeadlessUI.Disclosure
    HDisclosureButton: typeof HeadlessUI.DisclosureButton
    HDisclosurePanel: typeof HeadlessUI.DisclosurePanel
    HFocusTrap: typeof HeadlessUI.FocusTrap
    HListbox: typeof HeadlessUI.Listbox
    HListboxButton: typeof HeadlessUI.ListboxButton
    HListboxLabel: typeof HeadlessUI.ListboxLabel
    HListboxOption: typeof HeadlessUI.ListboxOption
    HListboxOptions: typeof HeadlessUI.ListboxOptions
    // headless-ui
    HMenu: typeof HeadlessUI.Menu
    HMenuButton: typeof HeadlessUI.MenuButton
    HMenuItem: typeof HeadlessUI.MenuItem
    HMenuItems: typeof HeadlessUI.MenuItems
    HPopover: typeof HeadlessUI.Popover
    HPopoverButton: typeof HeadlessUI.PopoverButton
    HPopoverGroup: typeof HeadlessUI.PopoverGroup
    HPopoverOverlay: typeof HeadlessUI.PopoverOverlay
    HPopoverPanel: typeof HeadlessUI.PopoverPanel
    HPortal: typeof HeadlessUI.Portal
    HPortalGroup: typeof HeadlessUI.PortalGroup
    HRadioGroup: typeof HeadlessUI.RadioGroup
    HRadioGroupDescription: typeof HeadlessUI.RadioGroupDescription
    HRadioGroupLabel: typeof HeadlessUI.RadioGroupLabel
    HRadioGroupOption: typeof HeadlessUI.RadioGroupOption
    HSwitch: typeof HeadlessUI.Switch
    HSwitchDescription: typeof HeadlessUI.SwitchDescription
    HSwitchGroup: typeof HeadlessUI.SwitchGroup
    HSwitchLabel: typeof HeadlessUI.SwitchLabel
    HTabs: typeof HeadlessUI.Tab
    HTabsGroup: typeof HeadlessUI.TabGroup
    HTabsList: typeof HeadlessUI.TabList
    HTabsPanel: typeof HeadlessUI.TabPanel
    HTabsPanels: typeof HeadlessUI.TabPanels
    HTransitionChild: typeof HeadlessUI.TransitionChild
    HTransitionRoot: typeof HeadlessUI.TransitionRoot
    VvErrorMessage: typeof ErrorMessage
    VvField: typeof Field
    // vee-validate
    VvForm: typeof Form
    'dw-2-way-toggle': typeof Dw2WayToggle
    dwUserAddIcon: typeof DwUserIcon
  }
}

let installed = false

export const uiComponents: Plugin = {
  install(app) {
    if (!installed) {
      // vee-validate
      app.component('vv-form', Form)
      app.component('vv-field', Field)
      app.component('vv-error-message', ErrorMessage)

      // headless-ui
      app.component('h-menu', HeadlessUI.Menu)
      app.component('h-menu-item', HeadlessUI.MenuItem)
      app.component('h-menu-items', HeadlessUI.MenuItems)
      app.component('h-menu-button', HeadlessUI.MenuButton)
      app.component('h-listbox', HeadlessUI.Listbox)
      app.component('h-listbox-label', HeadlessUI.ListboxLabel)
      app.component('h-listbox-button', HeadlessUI.ListboxButton)
      app.component('h-listbox-option', HeadlessUI.ListboxOption)
      app.component('h-listbox-options', HeadlessUI.ListboxOptions)
      app.component('h-combobox', HeadlessUI.Combobox)
      app.component('h-combobox-input', HeadlessUI.ComboboxInput)
      app.component('h-combobox-label', HeadlessUI.ComboboxLabel)
      app.component('h-combobox-button', HeadlessUI.ComboboxButton)
      app.component('h-combobox-option', HeadlessUI.ComboboxOption)
      app.component('h-combobox-options', HeadlessUI.ComboboxOptions)
      app.component('h-switch', HeadlessUI.Switch)
      app.component('h-switch-label', HeadlessUI.SwitchLabel)
      app.component('h-switch-group', HeadlessUI.SwitchGroup)
      app.component('h-switch-description', HeadlessUI.SwitchDescription)
      app.component('h-disclosure', HeadlessUI.Disclosure)
      app.component('h-disclosure-panel', HeadlessUI.DisclosurePanel)
      app.component('h-disclosure-button', HeadlessUI.DisclosureButton)
      app.component('h-dialog', HeadlessUI.Dialog)
      app.component('h-dialog-panel', HeadlessUI.DialogPanel)
      app.component('h-dialog-title', HeadlessUI.DialogTitle)
      app.component('h-dialog-overlay', HeadlessUI.DialogOverlay)
      app.component('h-dialog-backdrop', HeadlessUI.DialogBackdrop)
      app.component('h-dialog-description', HeadlessUI.DialogDescription)
      app.component('h-radio-group', HeadlessUI.RadioGroup)
      app.component('h-radio-group-label', HeadlessUI.RadioGroupLabel)
      app.component('h-radio-group-option', HeadlessUI.RadioGroupOption)
      app.component('h-radio-group-description', HeadlessUI.RadioGroupDescription)
      app.component('h-popover', HeadlessUI.Popover)
      app.component('h-popover-panel', HeadlessUI.PopoverPanel)
      app.component('h-popover-group', HeadlessUI.PopoverGroup)
      app.component('h-popover-button', HeadlessUI.PopoverButton)
      app.component('h-popover-overlay', HeadlessUI.PopoverOverlay)
      app.component('h-tabs', HeadlessUI.Tab)
      app.component('h-tabs-list', HeadlessUI.TabList)
      app.component('h-tabs-group', HeadlessUI.TabGroup)
      app.component('h-tabs-panel', HeadlessUI.TabPanel)
      app.component('h-tabs-panels', HeadlessUI.TabPanels)
      app.component('h-transition-root', HeadlessUI.TransitionRoot)
      app.component('h-transition-child', HeadlessUI.TransitionChild)
      app.component('h-focus-trap', HeadlessUI.FocusTrap)
      app.component('h-portal', HeadlessUI.Portal)
      app.component('h-portal-group', HeadlessUI.PortalGroup)

      app.component('dw-arrow-top-right-icon', DwArrowTopRightIcon)
      app.component('dw-arrow-icon', DwArrowIcon)
      app.component('dw-drag-handle-icon', DwDragHandleIcon)
      app.component('dw-mat-icon', DwMatIcon)
      app.component('dw-filter-icon', DwFilterIcon)
      app.component('dw-task-add-icon', DwTaskAddIcon)
      app.component('dw-trash-icon', DwTrashIcon)
      app.component('dw-file-icon', DwFileIcon)
      app.component('dw-hammer-icon', DwHammerIcon)
      app.component('dw-picture-plus-icon', DwPicturePlusIcon)
      app.component('dw-hex-download-file', DwHexDownloadFile)
      app.component('dw-hex-icon', DwHexagonIcon)
      app.component('dw-cart-icon', DwCartIcon)
      app.component('dw-i18n-icon', DwI18nIcon)
      app.component('dw-user-edit-icon', DwEditUserIcon)
      app.component('dw-user-add-icon', DwAddUserIcon)
      app.component('dw-user-login-circle-icon', DwUserLoginCircleIcon)
      app.component('dw-plus-outlined-icon', DwPlusOutlinedIcon)
      app.component('dw-refresh-icon', DwRefreshIcon)
      app.component('dw-user-icon', DwUserIcon)
      app.component('dw-watermelon-seeds-icon', DwWaterMelonSeedsIcon)
      app.component('dw-tooltip-icon', DwTooltipIcon)
      app.component('dw-loading-indicator', DwLoadingIndicator)
      app.component('dw-loading-indicator-circle', DwLoadingIndicatorCircle)
      app.component('dw-hide-password-icon', DwHidePasswordIcon)
      app.component('dw-show-password-icon', DwShowPasswordIcon)
      app.component('dw-search-icon', DwSearchIcon)
      app.component('dw-proalpha-icon', DwProalphaIcon)
      app.component('dw-clipboard-icon', DwClipboardIcon)

      app.component('dw-fade-transition', DwFadeTransition)
      app.component('dw-scale-transition', DwScaleTransition)
      app.component('dw-slide-fade-transition', DwSlideFadeTransition)

      app.component('dw-btn', DwBtn)
      app.component('dw-nav-btn', DwNavBtn)
      app.component('dw-filter-btn', DwFilterBtn)
      app.component('dw-indeterminate-btn', DwIndeterminateBtn)
      app.component('dw-2-way-toggle', Dw2WayToggle)
      app.component('dw-alert', DwAlert)
      app.component('dw-chip', DwChip)
      app.component('dw-input', DwInput)
      app.component('dw-checkbox', DwCheckbox)
      app.component('dw-combobox', DwCombobox)
      app.component('dw-modal', DwModal)
      app.component('dw-info-modal', DwInfoModal)
      app.component('dw-container', DwContainer)
      app.component('dw-notification', DwNotification)
      app.component('dw-page-header', DwPageHeader)
      app.component('dw-dots-menu-icon', DwDotsMenuIcon)

      app.component('dw-link', DwLink)
      app.component('dw-link-or-span', DwLinkOrSpan)
      app.component('dw-tertiary-navigation-item', DwTertiaryNavigationItem)

      app.component('dw-data-table', DwDataTable)
      app.component('dw-simple-table', DwSimpleTable)

      app.component('dw-breadcrumbs', DwBreadcrumbs)
      app.component('dw-text', DwText)

      app.component('dw-document', DwDocument)
      app.component('dw-data-card', DwDataCard)
      app.component('dw-no-document', DwNoDocument)
      app.component('dw-collapse', DwCollapse)

      app.component('dw-progress-bar', DwProgressBar)
      app.component('dw-three-dot-loading-indicator', DwThreeDotLoadingIndicator)
      app.component('dw-sorting-dropdown', DwSortingDropdown)

      installed = true
    }
  }
}
