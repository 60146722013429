<template>
  <div class="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 divide-x divide-gray-200 mb-4 sm:mb-6 group">
    <div class="w-0 flex-1 flex items-center p-4">
      <div class="w-full">
        <p class="text-sm font-medium text-gray-900">{{ safeTitle }}</p>
        <p class="mt-1 text-sm text-gray-500" v-if="message">{{ message }}</p>
      </div>
    </div>
    <div class="flex transition-opacity sm:opacity-0 sm:group-hover:opacity-100">
      <div class="flex flex-col divide-y divide-gray-200">
        <div class="h-0 flex-1 flex">
          <button
            type="button"
            @click="$emit('close')"
            class="w-full border border-transparent rounded-none rounded-tr-lg px-4 py-3 flex items-center justify-center text-sm font-medium focus:outline-none focus:z-10 focus:ring-2 focus:ring-primary-500">
            {{ $t('actions.close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    title: String,
    message: String,
    type: {
      type: String as PropType<'info' | 'success' | 'warning' | 'error'>,
      default: 'info'
    }
  },
  emits: ['close'],
  setup(props) {
    const i18n = useI18n()
    const typeText = computed<string>(() => {
      let typeText = ''

      switch(props.type) {
        case 'info':
          typeText = i18n.t('ui.notifications.type.info');
          break;
        case 'success':
          typeText = i18n.t('ui.notifications.type.success');
          break;
        case 'warning':
          typeText = i18n.t('ui.notifications.type.warning')
          break;
        case 'error':
          typeText = i18n.t('ui.notifications.type.error')
          break;
      }

      return typeText
    })
    const title = computed(() => {
      return props.title || typeText.value
    })

    return {
      safeTitle: title
    }
  }
})
</script>
