<template>
  <div class="border-solid border-2 rounded-lg w-[400px]" :class="[showDownloadLink ? '' : 'hover:bg-gray-50']">
    <!-- if the download link is visible, then the direct download on node click should be disabled -->
    <a
      :href="downloadUrl"
      download
      class="block p-3"
      :class="[showDownloadLink ? 'cursor-default' : '']"
      @click="(event) => { if(showDownloadLink) { event.preventDefault() }}">
      <div class="flex items-center">
        <div class="max-w-[40px] max-h-[40px] flex-shrink-0 flex-grow-0">
          <dw-file-icon :type=contentType></dw-file-icon>
        </div>
        <div class="ml-3">
          <div class="text-sm text-grey-900 break-all">
            {{ title }}
          </div>

          <div class="text-xs text-grey-500">
            {{ captionText }}
          </div>

          <div v-if="versions.length >= 2" class="mt-2">
            <dw-modal ref="versionsModal">
              <template #activator="{ on }">
                <button class="text-xs text-black" v-on="on">
                  {{ $t('ui.nodes.documentNode.nPreviousVersions', versions.length, { count: versions.length - 1 }) }}
                </button>
              </template>

              <template #body>
                <div class="flex justify-between items-center mb-4">
                  <h2 class="text-2xl font-semibold text-black break-all">
                    {{ $t('ui.nodes.documentNode.allVersionsOfDocument', { documentName: title }) }}
                  </h2>

                  <div class="flex-1 min-w-[30px]"></div>

                  <x-mark-icon class="h-[24px] w-[24px] text-gray-400 cursor-pointer" @click="$refs.versionsModal.close"></x-mark-icon>
                </div>

                <div class="w-full max-w-[90vw]">
                  <dw-data-table :items="versions" :headers="versionTableHeaders" class="w-full">
                    <template #item:version="{ item }">
                      <span>{{ item.version }}</span>
                      <dw-badge v-if="isLatestVersion(item)" class="ml-2" color="secondary">Current</dw-badge>
                    </template>
                    <template #item:createdAt="{ item }">
                      <span>{{ dateformat(item.createdAt, 'dd.mm.yyyy') }}</span>
                    </template>
                    <template #item:downloadUrl="{ item }">
                      <dw-btn @click="download(item.downloadUrl)">
                        {{ $t('actions.download') }}
                      </dw-btn>
                    </template>
                  </dw-data-table>
                </div>
              </template>
            </dw-modal>
          </div>

          <div v-if="showDownloadLink || $slots.actions" class="mt-2 text-xs">
            <a :href="downloadUrl" download @click.stop class="text-primary">Download latest Version</a>
            <span class="px-2 text-primary">·</span>
            <slot name="actions"></slot>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script lang="ts" setup>
import { XMarkIcon } from '@heroicons/vue/24/solid'
import dateformat from 'dateformat'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

interface Props {
  title: string
  language?: string
  versions?: DwDocumentVersion[] // sorted by version desc -> the latest version is the first item
  showDownloadLink?: boolean
}

interface DwDocumentVersion {
  contentType?: string
  version: string
  downloadUrl: string
  createdAt: string
}

const props = withDefaults(defineProps<Props>(), {
  versions: () => []
})
defineEmits<{
  (e: 'click:newVersion'): void
}>()
const i18n = useI18n()
const contentType = computed<string | undefined>(() => {
  return props.versions.length > 0 ? props.versions[0].contentType : undefined
})
const downloadUrl = computed<string | undefined>(() => {
  if (props.versions.length > 0) {
    return props.versions[0].downloadUrl
  } else {
    return undefined
  }
})
const captionText = computed<string>(() => {
  if (props.versions.length) {
    const latestVersion = props.versions[0]

    return [
      props.language,
      latestVersion.version ? `${i18n.t('ui.nodes.documentNode.systemVersion')}: ${latestVersion.version}` : undefined,
      latestVersion.createdAt ? `${i18n.t('ui.nodes.documentNode.date')}: ${dateformat(latestVersion.createdAt, 'dd/mm/yyyy')}` : undefined,
      props.versions.length >= 2 ? i18n.t('ui.nodes.documentNode.version1Date', { date: dateformat(props.versions[props.versions.length - 1].createdAt, 'dd/mm/yyyy') }) : undefined
    ].filter(i => !!i).join(' · ')
  } else {
    return `${props.language}`
  }
})
const versionTableHeaders = [
  {
    text: i18n.t('ui.nodes.documentNode.versionsTable.headers.systemVersion'),
    value: 'version',
    sortable: true
  }, {
    text: i18n.t('ui.nodes.documentNode.versionsTable.headers.createdAt'),
    value: 'createdAt',
    sortable: true
  }, {
    text: i18n.t('ui.nodes.documentNode.versionsTable.headers.download'),
    value: 'downloadUrl'
  }
]

function download(url: string) {
  const aElement = document.createElement('a')
  aElement.setAttribute('download', '')
  aElement.href = url
  aElement.click()
}

function isLatestVersion(version: DwDocumentVersion): boolean {
  return props.versions.findIndex(v => v.version === version.version) === 0
}
</script>
