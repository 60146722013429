<template>
  <div :class="[isInline ? 'items-center space-x-2 w-full' : 'flex-col space-y-1']" class="flex">
    <slot name="label">
      <label v-if="label" :for="inputName" class="text-sm font-medium text-grey-1000 whitespace-nowrap" v-text="label"></label>
    </slot>

    <Multiselect
      :id="inputName"
      ref="multiSelectRef"
      v-model="internalModel"
      :attrs="{ tabindex: tabindex }"
      :autocomplete="autocomplete"
      :can-clear="clearable"
      :can-deselect="nullable"
      :classes="{
        container: `${compact ? 'h-[2rem]' : 'h-[2.6rem]'} ${!disabled ? 'cursor-pointer bg-white' : 'bg-layer1'} ${$theme.isHexagon ? 'shadow' : ''} relative mx-auto w-full flex items-center justify-end box-border border border-layer2 rounded-md outline-none` ,
        containerDisabled: 'cursor-default',
        containerOpen: '',
        containerOpenTop: '',
        containerActive: 'shadow-[0_0_0_3px_rgba(0,0,0,0)] shadow-primary-500 hexagon:shadow-blue apv:shadow-apv-dark-green/50',
        wrapper: `${compact ? 'text-sm' : 'text-md'} relative mx-auto w-full flex items-center justify-end box-border outline-none ${ disabled ? 'text-grey-600' : ' text-grey-1000'}`,
        singleLabel: `${compact ? 'pl-2 pr-4' : 'pl-3.5 pr-16'} flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5`,
        singleLabelText: 'truncate block max-w-full',
        multipleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent pl-3.5 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
        search: `${compact ? 'text-sm pl-2' : 'pl-3.5'} w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 font-sans bg-white rounded rtl:pl-0 rtl:pr-3.5`,
        tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2 rtl:pl-0 rtl:pr-2',
        tag: 'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
        tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
        tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
        tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60 text-white',
        tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full min-h-[1.5rem] relative',
        tagsSearch: 'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 font-sans box-border w-full',
        tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
        placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent pl-3.5 text-grey-600 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
        // caret: 'bg-grey bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
        caretOpen: 'rotate-180 pointer-events-auto',
        clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
        // clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
        spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
        infinite: 'flex items-center justify-center w-full',
        infiniteSpinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
        dropdown: 'max-h-60 absolute -left-px -right-px -bottom-1.5 transform translate-y-full -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-md p-2 shadow-lg ring-1 ring-black ring-opacity-5',
        dropdownTop: '-translate-y-full top-px bottom-auto rounded-b-none rounded-t',
        dropdownHidden: 'hidden',
        options: 'space-y-1 text-md flex flex-col p-0 m-0 list-none font-semibold hexagon:font-medium',
        group: 'p-0 m-0',
        groupLabel: 'flex box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default',
        groupLabelPointable: 'cursor-pointer',
        groupLabelPointed: 'bg-gray-300 text-gray-700',
        groupLabelSelected: 'bg-green-600 text-white',
        groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
        groupLabelSelectedPointed: 'bg-green-600 text-white opacity-90',
        groupLabelSelectedDisabled: 'text-green-100 bg-green-600 bg-opacity-50 cursor-not-allowed',
        groupOptions: 'p-0 m-0',
        option: 'text-left cursor-pointer py-2 px-3 rounded-md hexagon:rounded text-black hexagon:text-grey-1000 hover:bg-layer1',
        optionPointed: '',
        optionSelected: 'bg-layer1',
        optionDisabled: 'text-grey-300 cursor-not-allowed',
        optionSelectedPointed: '',
        optionSelectedDisabled: 'text-green-100 bg-green-500 bg-opacity-50 cursor-not-allowed',
        noOptions: 'py-2 px-3 text-gray-600 bg-white text-left',
        noResults: 'py-2 px-3 text-gray-600 bg-white text-left',
        fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
        assist: 'sr-only',
        spacer: 'h-9 py-px box-content',
}"
      :close-on-select="mode === 'single'"
      :disabled="disabled"
      :label="itemText"
      :loading="loading"
      :mode="mode"
      :object="returnObject"
      :options="items"
      :placeholder="placeholder"
      :resolve-on-load="false"
      :searchable="searchable"
      :track-by="trackBy"
      :value-prop="itemValue"
      :style="inputStyle"
      @search-change="(search) => $emit('update:search', search)">
      <template #caret>
      <span class="inline-block pl-0.5 pr-2 z-10">
        <ChevronUpDownIcon v-if="!disabled" :class="{ 'text-grey-600': disabled }" aria-hidden="true" class="h-5 w-5"/>
      </span>
      </template>

      <template #clear="{ clear }">
        <div class="flex-shrink-0 h-4 w-4 text-grey-600" @click="clear">
          <x-mark-icon/>
        </div>
      </template>
    </Multiselect>
  </div>

  <span v-if="errorMessage" class="text-sm text-watermelon-600">{{ errorMessage }}</span>
</template>

<script lang="ts" setup>
// for the sake of simplicity we use any in this component
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import Multiselect from '@vueform/multiselect'
import { useField } from 'vee-validate'
import { computed, getCurrentInstance, ref, toRefs } from 'vue'

interface Props {
  autocomplete?: string
  clearable?: boolean
  compact?: boolean
  disabled?: boolean
  errorMessageLabel?: string
  itemText?: string
  itemValue?: string
  items?: any
  label?: string
  loading?: boolean
  mode?: 'single' | 'multiple' | 'tags'
  modelValue?: any
  name?: string
  nullable?: boolean
  placeholder?: string
  returnObject?: boolean
  searchable?: boolean
  tabindex?: number
  trackBy?: string
  inputStyle?: object
  inline?: boolean
}

defineEmits(['update:search', 'update:modelValue'])
defineExpose({
  updateValue,
  clearValue
})
const _props = withDefaults(defineProps<Props>(), {
  mode: 'single',
  autocomplete: 'off',
  openDirection: 'bottom',
  items: () => []
})

const isInline = computed(() => _props.inline ?? _props.compact)

const multiSelectRef = ref()
const props = toRefs(_props)
const inputName = computed<string>(() => props.name?.value || 'multiselect-' + getCurrentInstance()?.uid)
const { value, errorMessage } = useField(inputName, undefined, {
  label: props.errorMessageLabel || inputName
})

// NOTE: workaround for undefined v-models. assign at least an empty object or array to keep the reference to our v-model alive
const internalModel = computed({
  get: () => value.value ?? ref(props.mode.value === 'single' ? {} : []).value,
  set: (val) => value.value = val
})

function updateValue(v: any) {
  value.value = v
}

function clearValue() {
  multiSelectRef.value.clear()
}
</script>
