<template>      
  <dw-modal :open="modelValue" dialog-panel-class="w-[520px]">
    <template #header>
      <h2 class="text-2xl hexagon:italic hexagon:font-light">
        {{ titel }}
      </h2>
    </template>
    <template #text>
      <slot></slot>
    </template>
    <template #actions>
      <dw-btn
        @click="close()"
        variant="secondary">
        {{ $t('actions.close') }}
      </dw-btn>
    </template>
  </dw-modal>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue'

const emits = defineEmits(['update:modelValue'])

interface Props {
  modelValue: boolean,
  titel?: string
}

defineProps<Props>()

function close(): void {
  emits('update:modelValue', false)
}

</script>
