<template>
  <a
    href="#"
    class="relative inline-flex items-center px-2 py-2 first:rounded-l-md last:rounded-r-md border text-sm font-medium"
    :class="linkClasses"
    v-on="{...$attrs}">
    <slot/>
  </a>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'PaginationLink',

  props: {
    active: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false }
  },

  setup(props) {
    const linkClasses = computed(() => {
      if (props.active) {
        return 'bg-gray-200 border-gray-300 text-gray-800 hover:bg-gray-200'
      }

      if (props.disabled) {
        return 'cursor-default text-gray-400'
      }

      return 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
    })

    return { linkClasses }
  }
})
</script>
