<template>
  <dw-filter-btn
    hide-clear-icon
    :active="isActive"
    @click="toggle"
    :leading-icon="leadingIcon"
    :leading-icon-variant="internalModel === true ? 'default' : 'outlined'">
    <slot name="indeterminate" v-if="internalModel === undefined"></slot>
    <slot name="true" v-else-if="internalModel === true"></slot>
    <slot name="false" v-else></slot>
  </dw-filter-btn>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

interface Props {
  modelValue?: boolean | undefined
}

const props = defineProps<Props>()
const emits = defineEmits<{
  (e: 'update:modelValue', data: boolean | undefined): void
}>()
const internalModel = ref<boolean | undefined>(props.modelValue)
const isActive = computed<boolean>(() => {
  return typeof internalModel.value === 'boolean'
})
const leadingIcon = computed<string>(() => {
  return typeof internalModel.value === 'undefined' ? 'indeterminate_check_box' : internalModel.value === true ? 'check_box' : 'check_box_outline_blank'
})

function toggle() {
  switch (internalModel.value) {
    case undefined:
      internalModel.value = true
      break
    case true:
      internalModel.value = false
      break
    default:
      internalModel.value = undefined
  }
  emits('update:modelValue', internalModel.value)
}

watch(() => props.modelValue, (newValue) => {
  internalModel.value = newValue
})
</script>
