import { UserWithPermissionsModel } from '@hexagon/auth/models/user-with-permissions-model'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getDefaultRouteForLoggedInUser(user: UserWithPermissionsModel): string {
  /**
   * Uncomment if necessary
   *
   * if(user.permissions.canViewUsers) {
   *   return '...'
   * } else if (user.permissions.canEditUsers) {
   *   return '...'
   * }
   */

  return 'home'
}
