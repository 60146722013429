<template>
  <dw-link-or-span
    v-if="to"
    :class="cn(buttonVariants({ variant, size, other: $attrs.class }))"
    :to="disabled ? undefined : to">
    <span class="inner-bg block absolute inset-px pointer-events-none -z-10 transition-colors"></span>

    <span :class="[loading ? 'opacity-0' : '', 'transition-opacity flex justify-center items-center']">
      <slot></slot>
    </span>

    <dw-fade-transition>
      <dw-loading-indicator v-if="loading && $theme.isHexagon" class="absolute h-[1em]" use-current-color></dw-loading-indicator>
      <span v-else-if="loading" class="absolute">{{ $t('ui.input.loading') }}</span>
    </dw-fade-transition>
  </dw-link-or-span>

  <button v-else :class="cn(buttonVariants({ variant, size, other: $attrs.class }))" :disabled="disabled || loading" type="button">
    <span class="hexagon:text-xl inner-bg block absolute inset-px pointer-events-none -z-10 transition-colors"></span>

    <span :class="[loading ? 'opacity-0' : '', 'transition-opacity flex justify-center items-center']">
      <slot></slot>
    </span>

    <dw-fade-transition>
      <dw-loading-indicator v-if="loading && $theme.isHexagon" class="absolute h-[1em]" use-current-color></dw-loading-indicator>
      <span v-else-if="loading" class="absolute">{{ $t('ui.input.loading') }}</span>
    </dw-fade-transition>
  </button>
</template>

<script lang="ts" setup>
import { cn } from '@hexagon/ui/util'
import { cva, type VariantProps } from 'class-variance-authority'
import { RouteLocationRaw } from 'vue-router'

const buttonVariants = cva(
  'relative inline-flex items-center justify-center text-md rounded-lg font-medium transition-colors border border-transparent disabled:pointer-events-none disabled:text-grey-700 disabled:bg-grey-200 disabled:border-transparent apv:disabled:text-grey-700 apv:disabled:bg-grey-200 hexagon:rounded-none hexagon:border-none hexagon:disabled:text-grey-600 btp:rounded-[30px]',
  {
    variants: {
      variant: {
        default: 'bg-primary-800 hexagon:bg-blue disabled:!bg-grey-200 text-white hover:bg-primary-900 apv:bg-apv-dark-green apv:text-white apv:hover:bg-apv-green hexagon:text-white hexagon:hover:bg-dark-blue btp:bg-btp-blue btp:hover:bg-btp-dark-blue',
        secondary:
          'bg-white text-grey-900 border-layer2 hover:border-grey-400 hover:bg-grey-200 hexagon:bg-grey-400 hexagon:[&>.inner-bg]:bg-white hexagon:[&>.inner-bg]:hover:bg-layer2 hexagon:[&:disabled>.inner-bg]:invisible',
        warning: 'bg-rehab-400 text-black hover:bg-rehab-500 apv:bg-apv-tan apv:text-white apv:hover:bg-[#998B70] hexagon:bg-yellow hexagon:hover:bg-[#E5AD22]',
        purple: 'bg-primary-800 hexagon:bg-violet disabled:!bg-grey-200 text-white hover:bg-primary-900 apv:bg-apv-dark-green apv:text-white apv:hover:bg-apv-green hexagon:text-white hexagon:hover:bg-[#80447C]',
        projects: 'bg-white border-primary-800 apv:border-apv-dark-green hover:bg-blue/10 btp:border-btp-blue btp:text-btp-blue btp:hover:btp-blue text-primary-800 hexagon:text-blue apv:text-apv-dark-green apv:hover:!bg-apv-green/25 apv:font-semibold hover:bg-primary-800/25 hexagon:bg-blue hexagon:[&>.inner-bg]:bg-white hexagon:[&>.inner-bg]:hover:bg-white/90 hexagon:[&:disabled>.inner-bg]:invisible',
        danger: 'bg-red text-white hover:bg-watermelon-900'
      },
    size: {
        default: 'px-5 h-[40px] hexagon:px-10 hexagon:hx-clip-btn hexagon:[&>.inner-bg]:hx-clip-btn',
        sm: 'px-3 h-[28px] text-sm hexagon:px-6 hexagon:hx-clip-btn--sm hexagon:[&>.inner-bg]:hx-clip-btn--sm'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

interface Props {
  disabled?: boolean
  loading?: boolean
  size?: VariantProps<typeof buttonVariants>['size']
  to?: RouteLocationRaw
  variant?: VariantProps<typeof buttonVariants>['variant']
}

defineProps<Props>()
</script>
