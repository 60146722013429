<template>
  <div class="p-1 space-x-1 bg-grey-50 border border-[#e5e7eb] inline-flex rounded">
    <button
      :class="[internalModel ? 'bg-primary-400 text-white' : 'text-grey-900']"
      class="rounded px-2.5 py-1 flex items-center justify-center text-xs font-semibold hexagon:font-medium leading-5"
      @click="internalModel = true">{{ firstLabelText }}
    </button>
    <button
      :class="[internalModel ? 'text-grey-900' : 'bg-primary-400 text-white']"
      class="rounded px-2.5 py-1 flex items-center justify-center text-xs font-semibold hexagon:font-medium leading-5" @click="internalModel = false">
      {{ secondLabelText }}
    </button>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'

interface Props {
  firstLabelText?: string
  secondLabelText?: string
  modelValue?: boolean
}

const props = defineProps<Props>()
const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()
const internalModel = ref<boolean>(true)

watch(() => internalModel.value, (value) => {
  emits('update:modelValue', value)
})
watch(() => props.modelValue, (value) => {
  if (typeof value === 'boolean') {
    internalModel.value = value
  }
}, { immediate: true })
</script>
