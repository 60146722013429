<template>
  <svg
    v-if="$theme.isHexagon"
    :class="cn('h-6', $attrs.class)"
    fill="none"
    viewBox="0 0 212 90"
    xmlns="http://www.w3.org/2000/svg">
    <g
      :style="styles"
      class="origin-center ease-[cubic-bezier(0.88, 0, 0.41, 1.03)] duration-[514ms] delay-[180ms]"
      style="transform-box: fill-box;">
      <path
        :fill="useCurrentColor ? 'currentColor' : '#76ba5d'"
        d="M108.932 2.67308L104.292 0L72.4111 18.3623L77.0462 21.0353V26.3815L108.932 8.01457V2.67308Z"/>
      <path
        :fill="useCurrentColor ? 'currentColor' : '#e65250'"
        d="M99.657 83.4475L104.292 86.1206L136.178 67.7537L131.543 65.0852V65.0573L131.538 65.0526V59.7391L99.657 78.106V83.4475Z"/>
      <path
        :fill="useCurrentColor ? 'currentColor' : '#f29129'"
        d="M132.383 64.573L137.018 67.2461L141.658 64.573V27.8391L137.023 30.5076L132.378 27.8345V58.2908L132.383 58.2861V64.573Z"/>
      <path d="M132.383 64.573V58.2861L132.378 58.2908V64.573H132.383Z" fill="#F29129"/>
      <path
        :fill="useCurrentColor ? 'currentColor' : '#009de2'"
        d="M76.2014 21.5197L71.5663 18.8466L66.9312 21.515V58.2489L71.5616 55.5804L76.206 58.2535V27.8298L76.2014 27.8345V21.5197Z"/>
      <path
        :fill="useCurrentColor ? 'currentColor' : '#009de2'"
        d="M71.5664 18.8466L76.2015 21.5197V27.8345L76.2062 27.8298V21.515L71.5664 18.8466Z"/>
      <path
        :fill="useCurrentColor ? 'currentColor' : '#fbc134'"
        d="M137.023 29.5435L141.662 26.8705V21.5243L109.777 3.16205V8.49889L105.132 11.172L137.023 29.5435Z"/>
      <path
        :fill="useCurrentColor ? 'currentColor' : '#a1569d'"
        d="M66.9219 59.2175V64.5637L98.8075 82.9306V77.5891L103.452 74.916L71.5617 56.5444L66.9219 59.2175Z"/>
      <path
        :fill="useCurrentColor ? 'currentColor' : '#858685'"
        d="M104.292 53.1402C109.879 53.1402 114.407 48.6221 114.407 43.0487C114.407 37.4752 109.879 32.9571 104.292 32.9571C98.7059 32.9571 94.1772 37.4752 94.1772 43.0487C94.1772 48.6221 98.7059 53.1402 104.292 53.1402Z"/>
    </g>
  </svg>
  
  <btp-loading-indicator v-else-if="$theme.isBTP" :class="$attrs.class"/>

  <div
    v-else
    :class="cn('h-[50px] w-[50px] rounded-[50%] animate-spin-reverse gradient text-primary-800 apv:text-apv-green', $attrs.class)" />
</template>

<script lang="ts" setup>
import { useLogoRotation } from '@hexagon/ui/use/use-logo-rotation'
import BtpLoadingIndicator from './btp/btp-loading-indicator.vue'
import { cn } from '@hexagon/ui/util'

interface Props {
  useCurrentColor?: boolean
}

defineProps<Props>()

const { styles } = useLogoRotation()
</script>

<style scoped>
.gradient {
  background: radial-gradient(farthest-side, currentColor 96%, #0000) top/9px 9px no-repeat, conic-gradient(currentColor 0%, #0000);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000000 0);
}
</style>
