<template>
  <div
    class="text-lg ml-auto mt-1 flex items-center cursor-pointer w-1/4"
    @click="toggleCollapse">
    <div class="ml-auto flex rounded hover:bg-layer2 space-x-1">
      <slot v-if="!isOpen" name="header" />
      <ChevronDownIcon :class="isOpen? 'rotate-180': ''" class="w-5 h-5 arrow rounded-full transition-all my-auto"/>
    </div>
  </div>
  <dw-slide-fade-transition>
    <div v-if="isOpen" class="pt-3 pb-2 mt-3 w-full">
      <slot></slot>
    </div>
  </dw-slide-fade-transition>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'

interface Props {
  modalValue?: boolean
}

const props = defineProps<Props>()

const isOpen = ref(props.modalValue)
const toggleCollapse = () => {
  isOpen.value = !isOpen.value
}
</script>
