<template>
  <transition
    enter-active-class="transition ease-out"
    enter-from-class="transform opacity-0 translate-y-[-20px]"
    enter-to-class="transform opacity-100"
    leave-active-class="transition ease-in duration-200"
    leave-from-class="transform opacity-100"
    leave-to-class="transform opacity-0 translate-y-[-20px]">
    <slot></slot>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>
