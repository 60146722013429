import { defineStore } from 'pinia'
import { NotificationType } from '../model/enum/notification-enum'
import { NotificationModel } from '../model/notification-model'

interface NotificationState {
  messages: NotificationModel[]
}

const DEFAULT_TIMEOUT = 5000

export const useNotificationStore = defineStore({
  id: 'notification',
  state: (): NotificationState => ({
    messages: []
  }),
  getters: {
    messagesOfGroup() {
      return (group?: string) => this.messages.filter(m => m.group === (group || ''))
    }
  },
  actions: {
    error(error: string | Error | unknown, config: { group?: string, timeout?: number } = { group: undefined, timeout: DEFAULT_TIMEOUT }) {
      // IDEA: show additional button for reporting this error to sentry
      let message = ''
      switch (typeof error) {
        case 'object':
          message = error instanceof Error ? error.message.toString() : error?.toString() || ''
          break
        case 'string':
          message = error
          break
      }
      this._create({
        message: message,
        type: NotificationType.ERROR,
        group: config.group,
        timeout: config.timeout
      })
    },
    success(message: string, config: { group?: string, timeout?: number } = { group: undefined, timeout: DEFAULT_TIMEOUT }) {
      this._create({ message, type: NotificationType.SUCCESS, group: config.group, timeout: config.timeout })
    },
    info(message: string, config: { group?: string, timeout?: number } = { group: undefined, timeout: DEFAULT_TIMEOUT }) {
      this._create({ message, type: NotificationType.INFO, group: config.group, timeout: config.timeout })
    },
    warning(message: string, config: { group?: string, timeout?: number } = { group: undefined, timeout: DEFAULT_TIMEOUT }) {
      this._create({ message, type: NotificationType.WARNING, group: config.group, timeout: config.timeout })
    },
    remove(id: string) {
      const index = this.messages.findIndex(m => m.id === id)
      if (index > -1) {
        this.messages.splice(index, 1)
      }
    },
    _create(params: { message: string, type: NotificationType, timeout?: number, group?: string }) {
      const generatedId = generateId()
      this.messages.push({ id: generatedId, message: params.message, type: params.type, group: params.group || '' })

      if (params.timeout && params.timeout > 0) {
        setTimeout(() => {
          this.remove(generatedId)
        }, params.timeout)
      }
    }
  }
})

function generateId() {
  return Math.random().toString(16).slice(2)
}
