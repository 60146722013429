import axios from 'axios'
import { PermissionsModel } from '../models/permissions-model'
import { UserWithPermissionsModel } from '../models/user-with-permissions-model'

export interface GatewaySettings {
  accessToken?: string
  baseUrl: string,
}

interface UserModel {
  email: string,
  id: string,
  name?: string,
}

interface UserProfileModel {
  permissions: string[]
  user: UserModel,
}

export class APIGateway {
  constructor(private settings: GatewaySettings) {
  }

  set accessToken(token: string | undefined) {
    this.settings.accessToken = token
  }

  async getMe(): Promise<UserWithPermissionsModel> {
    return APIGatewayMapper.mapUserProfileToUserWithPermissions((await axios.get<UserProfileModel>('/api/v1/users/me', {
      baseURL: this.settings.baseUrl,
      headers: { 'Authorization': 'Bearer ' + this.settings.accessToken }
    })).data)
  }
}

export class APIGatewayMapper {
  static mapUserProfileToUserWithPermissions(userProfile: UserProfileModel): UserWithPermissionsModel {
    return {
      id: userProfile.user.id,
      email: userProfile.user.email,
      name: userProfile.user.name,
      permissions: APIGatewayMapper.mapPermissionsArrayToPermissionsModel(userProfile.permissions)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static mapPermissionsArrayToPermissionsModel(permissions: string[]): PermissionsModel {
    return {
      PROJECT_CAN_EXPORT_TO_PROALPHA: permissions.includes('PROJECT_CAN_EXPORT_TO_PROALPHA'),
      PROJECT_CAN_REOPEN_ALL: permissions.includes('PROJECT_CAN_REOPEN_ALL'),
      PROJECT_CAN_REOPEN_IN_SAME_COUNTRY: permissions.includes('PROJECT_CAN_REOPEN_IN_SAME_COUNTRY'),
      NOTIFY_ON_NEW_OFFER_IN_SAME_COUNTRY: permissions.includes('NOTIFY_ON_NEW_OFFER_IN_SAME_COUNTRY'),
      NOTIFY_DEFAULT_EMAIL_ON_NEW_OFFER: permissions.includes('NOTIFY_DEFAULT_EMAIL_ON_NEW_OFFER'),
      STOCK_AMOUNTS_CAN_VIEW: permissions.includes('STOCK_AMOUNTS_CAN_VIEW'),
      NOTIFY_ON_NEW_USER_CREATED: permissions.includes('NOTIFY_ON_NEW_USER_CREATED'),
      CUSTOMER_CAN_SHOW_ALL: permissions.includes('CUSTOMER_CAN_SHOW_ALL'),
      CUSTOMER_CAN_SHOW_IN_SAME_COUNTRY: permissions.includes('CUSTOMER_CAN_SHOW_IN_SAME_COUNTRY'),
    }
  }
}
