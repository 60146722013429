<template>
  <div class="bg-white flex items-end sm:items-center justify-end flex-col sm:flex-row">
    <PaginationInfo>
      <slot :end="currentEnd" :start="currentStart" :total="total" name="pagination-info">
        <i18n-t keypath="ui.dataTable.pagination.info" scope="global" tag="span">
          <template v-slot:firstItemInPage>
            <span class="font-semibold hexagon:font-medium">{{ currentStart }}</span>
          </template>
          <template v-slot:lastItemInPage>
            <span class="font-semibold hexagon:font-medium">{{ currentEnd }}</span>
          </template>
          <template v-slot:totalItems>
            <span class="font-semibold hexagon:font-medium">{{ total }}</span>
          </template>
        </i18n-t>
      </slot>
    </PaginationInfo>

    <div class="flex mt-2 sm:mt-0 ml-0 sm:ml-5">
      <div class="mr-3">
        <label class="sr-only" for="location">{{ $t('ui.dataTable.pagination.perPage') }}</label>
        <select
          :value="perPage"
          class="block pl-3 pr-7 py-2 text-base border border-gray-300 sm:text-sm rounded-md outline-none focus:ring-1 focus:ring-inset"
          name="per_page"
          @input="changePageSize(parseInt($event.target.value))">
          <option
            v-for="size in itemsPerPageOptions"
            :key="`per_page_${size}`"
            :selected="size === perPage"
            :value="size"
            v-text="getSizeText(size)"/>
        </select>
      </div>

      <PaginationButtons>
        <pagination-link key="page_previous" :disabled="isInFirstPage" @click.prevent="gotoPreviousPage">
          <span class="sr-only">{{ $t('ui.dataTable.pagination.previous') }}</span>
          <ChevronLeftIcon aria-hidden="true" class="h-5 w-5"/>
        </pagination-link>

        <slot :goToPage="goToPageNumber" :totalPages="totalPages" name="goToN"></slot>

        <pagination-link key="page_next" :disabled="isInLastPage" @click.prevent="gotoNextPage">
          <span class="sr-only">{{ $t('ui.dataTable.pagination.next') }}</span>
          <ChevronRightIcon aria-hidden="true" class="h-5 w-5"/>
        </pagination-link>
      </PaginationButtons>
    </div>
  </div>
</template>

<script lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/solid'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import PaginationButtons from './pagination-buttons.vue'
import PaginationInfo from './pagination-info.vue'
import PaginationLink from './pagination-link.vue'

const LEFT = 'left'

export default defineComponent({
  name: 'Pagination',
  components: { PaginationButtons, PaginationInfo, PaginationLink, ChevronLeftIcon, ChevronRightIcon },
  props: {
    total: { type: Number, required: true },
    perPage: { type: Number, required: true },
    page: { type: Number, required: false, default: 0 },
    maxVisibleButtons: { type: Number, required: false, default: 5 },
    itemsPerPageOptions: { type: Array, required: true }
  },
  emits: ['update:page', 'update:per-page'],
  setup(props, { emit }) {
    const currentStart = computed(() => props.total > 0 ? props.page * props.perPage + 1 : 0)
    const currentEnd = computed(() => props.perPage === -1 ? props.total : props.total > (props.page + 1) * props.perPage ? (props.page + 1) * props.perPage : props.total)
    const totalPages = computed(() => props.perPage === -1 ? 1 : Math.ceil(props.total / props.perPage))
    const i18n = useI18n()

    const startPage = computed(() => {
      if (props.page === 0) {
        return 1
      }

      if (props.page === totalPages.value) {
        return totalPages.value - props.maxVisibleButtons + 1
      }

      return props.page
    })
    const endPage = computed(() => Math.min(startPage.value + props.maxVisibleButtons - 1, totalPages.value))

    const pages = computed(() => {
      const range = []

      for (let i = startPage.value; i <= endPage.value; i += 1) {
        if (i > 0) {
          range.push(i)
        }
      }

      return range
    })

    const isInFirstPage = computed(() => props.page <= 0)
    const isInLastPage = computed(() => props.page >= totalPages.value - 1)

    const goToPageNumber = (page: number) => {
      emit('update:page', page)
    }
    const gotoFirstPage = () => goToPageNumber(0)
    const gotoLastPage = () => goToPageNumber(totalPages.value - 1)
    const gotoNextPage = () => goToPageNumber((props.page < totalPages.value - 1) ? props.page + 1 : totalPages.value - 1)
    const gotoPreviousPage = () => goToPageNumber((props.page <= 0) ? 0 : props.page - 1)

    const showDots = (position = LEFT): boolean => {
      const number = position === LEFT ? 1 : totalPages.value
      const nextNumber = position === LEFT ? 2 : totalPages.value - 1

      return !pages.value.includes(number) || !pages.value.includes(nextNumber)
    }

    function getSizeText(size: number): string {
      return size !== -1 ? size.toString() : i18n.t('ui.dataTable.pagination.all')
    }

    function changePageSize(pageSize: number) {
      gotoFirstPage()
      emit('update:per-page', pageSize)
    }

    return {
      currentStart,
      currentEnd,
      totalPages,
      pages,
      isInFirstPage,
      isInLastPage,
      goToPageNumber,
      gotoFirstPage,
      gotoLastPage,
      gotoNextPage,
      gotoPreviousPage,
      showDots,
      getSizeText,
      changePageSize
    }
  }
})
</script>
