export const supportedLanguageCodes =  ['en', 'de', 'fr', 'it', 'es', 'hu', 'nl', 'pl', 'fi', 'sl', 'da']
export const supportedCountryLocales = {
  en: () => Promise.resolve(import('i18n-iso-countries/langs/en.json')),
  de: () => Promise.resolve(import('i18n-iso-countries/langs/de.json')),
  fr: () => Promise.resolve(import('i18n-iso-countries/langs/fr.json')),
  it: () => Promise.resolve(import('i18n-iso-countries/langs/it.json')),
  es: () => Promise.resolve(import('i18n-iso-countries/langs/es.json')),
  hu: () => Promise.resolve(import('i18n-iso-countries/langs/hu.json')),
  nl: () => Promise.resolve(import('i18n-iso-countries/langs/nl.json')),
  pl: () => Promise.resolve(import('i18n-iso-countries/langs/pl.json')),
  fi: () => Promise.resolve(import('i18n-iso-countries/langs/fi.json')),
  sl: () => Promise.resolve(import('i18n-iso-countries/langs/sl.json')),
  da: () => Promise.resolve(import('i18n-iso-countries/langs/da.json'))
}