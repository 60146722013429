import { RouteLocation, useRouter } from 'vue-router'

export function useAuthRedirect() {
  const AFTER_LOGIN_REDIRECT_FIELD_NAME = 'after-login-redirect-path'
  const router = useRouter()

  function redirect(fallbackRoute: RouteLocation = router.resolveIntro()) {
    const redirectPathOrUri = window.localStorage.getItem(AFTER_LOGIN_REDIRECT_FIELD_NAME)
    window.localStorage.removeItem(AFTER_LOGIN_REDIRECT_FIELD_NAME)

    if (redirectPathOrUri) {
      if (redirectPathOrUri.startsWith('http')) {
        window.location.href = redirectPathOrUri
      } else if (router.resolve(redirectPathOrUri)) {
        router.replace(redirectPathOrUri)
      } else {
        router.replace(fallbackRoute)
      }
    } else {
      router.replace(fallbackRoute)
    }
  }

  function setRedirectPathOrUri(pathOrUri: string) {
    window.localStorage.setItem(AFTER_LOGIN_REDIRECT_FIELD_NAME, pathOrUri)
  }

  return {
    redirect,
    setRedirectPathOrUri
  }
}
