// eslint-disable-next-line boundaries/element-types
import { useMonitoring } from '@hexagon/common/use/use-monitoring'
import { ILogger, Log, SigninRedirectArgs, User, UserManager, UserManagerSettings } from 'oidc-client-ts'
import { computed, ComputedRef, ref } from 'vue'
import { UserWithPermissionsModel } from '../models/user-with-permissions-model'
import { APIGateway, GatewaySettings } from './api-gateway'

interface AuthSettings {
  gateway: GatewaySettings
  logger?: ILogger
  oidc: UserManagerSettings,
}

export class Auth {
  private _oidcUser = ref<User | null>(null)
  private apiGateway: APIGateway
  private logger: ILogger
  private userManager: UserManager
  private _user = ref<UserWithPermissionsModel | null>(null)
  private _readonlyUser = computed<UserWithPermissionsModel | null>(() => this._user.value)
  private _isAuthenticated = computed(() => Boolean(this._user.value).valueOf())
  private _accessToken = computed<string | null>(() => this._oidcUser.value?.access_token || null)

  constructor(private settings: AuthSettings) {
    this.logger = this.settings.logger || console
    Log.setLogger(this.settings.logger || console)
    Log.setLevel(import.meta.env.PROD ? Log.ERROR : Log.DEBUG)

    this.userManager = new UserManager(settings.oidc)
    this.apiGateway = new APIGateway(settings.gateway)

    this.userManager.events.addUserLoaded(async (u) => {
      this._oidcUser.value = u
      this.apiGateway.accessToken = u.access_token
      await this.renewMe()
    })

    this.userManager.events.addUserSignedOut(() => {
      this._oidcUser.value = null
      this._user.value = null
      this.apiGateway.accessToken = undefined
      this.userManager.stopSilentRenew()
    })

    this.userManager.events.addUserUnloaded(() => {
      this._oidcUser.value = null
      this._user.value = null
      this.apiGateway.accessToken = undefined
      this.userManager.stopSilentRenew()
    })

    this.userManager.events.addSilentRenewError((error) => {
      this.logger.error('Silent renew failed. Error sent to sentry.', error)
      useMonitoring().captureException(error)
    })

    this.userManager.events.addAccessTokenExpired(() => {
      refreshManually()
    })

    /**
     * _validateIdTokenAttributes() must be overridden, because it validates the id token with auth_time and that can cause unexpected logouts
     * The below code bypasses these checks by omitting the second parameter of the original method
     *
     * This code can be deleted as soon as auth_time in our id_tokens are set to "0"
     */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    const originalIdTokenValidatorMethod = this.userManager._client._validator._validateIdTokenAttributes
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.userManager._client._validator._validateIdTokenAttributes = function () {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      originalIdTokenValidatorMethod.bind(that.userManager._client._validator)(arguments[0])
    }

    // fix(#14): 401 request errors
    // if there is a user and the access token is out of date when window is refocused, then refresh
    window.addEventListener('focus', () => {
      if (this._oidcUser.value?.expired) {
        refreshManually()
      }
    })

    const refreshManually = () => {
      this.userManager.stopSilentRenew()
      this.userManager.signinSilent()
          .then(() => this.userManager.startSilentRenew())
          .catch((e) => {
            useMonitoring().captureException(e)
            this.logger.error('Manual silent sign in failed. Error sent to sentry.', e)
          })
    }
  }

  get user(): ComputedRef<UserWithPermissionsModel | null> {
    return this._readonlyUser
  }

  get isAuthenticated(): ComputedRef<boolean> {
    return this._isAuthenticated
  }

  get accessToken(): ComputedRef<string | null> {
    return this._accessToken
  }

  /**
   * Initializes tokens, user and permissions
   * Should be called before any auth-related application code is executed
   *
   * This method ensures, that after a page refresh the access token and the application provided user and permissions are fresh
   */
  async initialize() {
    try {
      this._oidcUser.value = await this.userManager.getUser()

      if (this._oidcUser.value) {
        this._oidcUser.value = await this.userManager.signinSilent()

        if (this._oidcUser.value) {
          this.logger.debug('user got refreshed', this._oidcUser)
        }

        this.apiGateway.accessToken = this._oidcUser.value?.access_token
        await this.renewMe()
        this.userManager.startSilentRenew()
      }
    } catch (e) {
      this.userManager.stopSilentRenew()
      this.logger.warn('User initialization failed. Defaulting to logged out user.', e)
    }
  }

  login(args?: SigninRedirectArgs) {
    this.userManager.signinRedirect(args)
  }

  logout(redirectUri?: string) {
    if (!redirectUri) {
      redirectUri = window.location.origin
    }

    this.userManager.signoutRedirect({ post_logout_redirect_uri: redirectUri, id_token_hint: this._oidcUser.value?.id_token })
  }
  
  async handleCallback() {
    await this.userManager.removeUser()
    await this.userManager.signinRedirectCallback()
    await this.renewMe()
    this.userManager.startSilentRenew()
  }

  private async renewMe(): Promise<void> {
    try {
      this._user.value = await this.apiGateway.getMe()
    } catch (e) {
      useMonitoring().captureException(e)
      this.logger.warn('user profile couldn\'t be updated')
    }
  }
}
