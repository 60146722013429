import { Composer } from 'vue-i18n'
import { z } from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'

export function registerI18nMessages(i18n: Composer) {
  z.setErrorMap(makeZodI18nMap({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    t: function (i18nKey: string, params: Record<string, string>) {
      // we only want to translate errors and not field paths
      if (i18nKey.startsWith('error') || i18nKey.startsWith('types') || i18nKey.startsWith('validation')) {
        return i18n.t('zod.' + i18nKey, params)
      } else {
        return i18nKey
      }
    }
  }))
}
