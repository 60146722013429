<script lang="ts">
import { useNotificationStore } from '@hexagon/notifications/store/notification-store'
import { computed, defineComponent, h, inject, TransitionGroup } from 'vue'

export default defineComponent({
  props: {
    maxNotifications: {
      type: Number,
      default: 5
    },
    transitionGroupClasses: {
      default: () => {
        return {
          enterActiveClass: 'transform ease-out duration-300 transition',
          enterFromClass: 'translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4',
          enterToClass: 'translate-y-0 opacity-100 sm:translate-x-0',
          leaveActiveClass: 'transition ease-in duration-300 absolute',
          leaveFromClass: 'opacity-100',
          leaveToClass: 'opacity-0',
          moveClass: 'transition duration-300'
        }
      }
    }
  },
  setup(props) {
    const notificationContext = inject('notificationContext', () => ({ group: '', position: 'top' }), true)
    const notificationStore = useNotificationStore()
    const notificationsOfGroup = computed(() => {
      return notificationStore.messagesOfGroup(notificationContext.group)
    })
    const sortedNotifications = computed(() => {
      if (notificationContext.position === 'bottom') {
        return [...notificationsOfGroup.value].slice(0, props.maxNotifications)
      }

      // if not bottom reverse the array
      return [...notificationsOfGroup.value]
          .reverse()
          .slice(0, props.maxNotifications)
    })

    const close = (id: string) => {
      notificationStore.remove(id)
    }

    return {
      notifications: notificationStore.messages,
      sortedNotifications,
      notificationsOfGroup,
      close
    }
  },
  render() {
    return h(
        TransitionGroup,
        {
          'enter-active-class': this.transitionGroupClasses.enterActiveClass,
          'enter-from-class': this.transitionGroupClasses.enterFromClass,
          'enter-to-class': this.transitionGroupClasses.enterToClass,
          'leave-active-class': this.transitionGroupClasses.leaveActiveClass,
          'leave-from-class': this.transitionGroupClasses.leaveFromClass,
          'leave-to-class': this.transitionGroupClasses.leaveToClass,
          'move-class': this.transitionGroupClasses.moveClass,

          // fix for leaving items to not transition to top of parent container
          // https://forum.vuejs.org/t/transition-group-leave-transition-w-position-absolute-jumping-to-top-left-flip/12258/4
          onBeforeLeave(e) {
            const el = e as HTMLElement
            const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)
            el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`
            el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`
            el.style.width = width
            el.style.height = height
          }
        },
        {
          default: () => {
            return this.$slots.default?.({
              notifications: this.sortedNotifications,
              close: this.close
            })
          }
        }
    )
  }
})
</script>
