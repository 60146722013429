import { addBreadcrumb, captureEvent, captureException, captureMessage, setContext, setTag } from '@sentry/vue'

export function useMonitoring(): { captureException: typeof captureException, captureEvent: typeof captureEvent, captureMessage: typeof captureMessage, addBreadcrumb: typeof addBreadcrumb, setTag: typeof setTag, setContext: typeof setContext } {
  return {
    captureException: (...args) => captureException(...args),
    captureEvent: (...args) => captureEvent(...args),
    captureMessage: (...args) => captureMessage(...args),
    addBreadcrumb: (...args) => addBreadcrumb(...args),
    setTag: (...args) => setTag(...args),
    setContext: (...args) => setContext(...args)
  }
}