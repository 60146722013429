import { computed, type Plugin, reactive, ref, watch } from 'vue'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $theme: {
      name: '' | 'hexagon' | 'btp' | 'apv'
      isBase: boolean
      isHexagon: boolean
      isAPV: boolean
      isBTP: boolean
    }
  }
}

export const themeSelectorPlugin: Plugin = {
  install(app) {
    let themeName: '' | 'hexagon' | 'btp' | 'apv' = ''

    switch (import.meta.env.VITE_APP_INSTANCE) {
      case 'HEXAGON':
        themeName = 'hexagon'
        break
      case 'BTP':
        themeName = 'btp'
        break
      case 'PARACALC':
        themeName = 'apv'
        break
      default:
        themeName = ''
    }
    const themeNameRef = ref(themeName)
    const theme = reactive({
      name: themeNameRef,
      isBase: computed<boolean>(() => !themeNameRef.value),
      isHexagon: computed<boolean>(() => themeNameRef.value === 'hexagon'),
      isAPV: computed<boolean>(() => themeNameRef.value === 'apv'),
      isBTP: computed<boolean>(() => themeNameRef.value === 'btp')
    })

    app.config.globalProperties.$theme = theme

    watch(themeNameRef, (themeName) => {
      const html = document.querySelector('html')
      if (html) {
        html.dataset.theme = themeName
      }
    }, { immediate: true })
  }
}
