<template>
  <span>
    <label class="flex items-center text-grey-1000">
      <input
        :checked="checked"
        :disabled="disabled"
        :name="inputName"
        class="sr-only"
        type="checkbox"
        @input="handleChange($event.target.checked);"
      />

      <svg
        v-if="$theme.isHexagon"
        :style="{height: props.size === 'md' ? '38px' : '24px' }"
        class="unchecked mr-2"
        fill="none"
        style="width: auto;"
        viewBox="0 0 31 38"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.68 37.3601L0 27.9501V9.55014L15.68 0.890137L31 9.56014V27.9401L15.68 37.3601ZM3 26.2501L15.66 33.8501L28 26.2601V11.3001L15.66 4.33014L3 11.3201V26.2601V26.2501Z"
          fill="#BEBEBE"/>
      </svg>


      <svg
        v-if="$theme.isHexagon"
        :style="{height: props.size === 'md' ? '38px' : '24px' }"
        class="checked text-blue mr-2"
        fill="none"
        style="width: auto;"
        viewBox="0 0 31 38"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M15.68 0.890137L0 9.55014V27.9501L15.68 37.3601L31 27.9401V9.56014L15.68 0.890137Z" fill="currentColor"/>
        <path
          d="M23.4502 13.9202C22.7902 13.2602 21.7202 13.2602 21.0602 13.9202L13.8102 21.1702L9.94018 17.3002L9.75018 17.1402C9.09018 16.6502 8.15018 16.7002 7.55018 17.3002C6.89018 17.9602 6.89018 19.0302 7.55018 19.6902L12.6202 24.7602L12.8002 24.9202C12.8002 24.9202 12.8202 24.9202 12.8302 24.9302C12.9802 25.0302 13.1402 25.1102 13.3002 25.1602C13.3302 25.1602 13.3502 25.1802 13.3802 25.1802C13.5502 25.2202 13.7202 25.2402 13.8902 25.2302C13.9202 25.2302 13.9402 25.2302 13.9702 25.2302C14.1402 25.2102 14.3102 25.1702 14.4802 25.1002C14.5002 25.1002 14.5102 25.0802 14.5302 25.0702C14.7002 24.9902 14.8602 24.8902 15.0002 24.7502L23.4502 16.3002L23.6102 16.1102C24.1002 15.4502 24.0502 14.5102 23.4502 13.9102V13.9202Z"
          fill="white"/>
      </svg>

      <svg
        v-if="!$theme.isHexagon"
        :style="{height: props.size === 'md' ? '36px' : '18px' }"
        class="checked text-primary-800 apv:text-apv-dark-green btp:text-btp-blue mr-2"
        fill="none"
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H28C32.1421 0.5 35.5 3.85786 35.5 8V28C35.5 32.1421 32.1421 35.5 28 35.5H8C3.85786 35.5 0.5 32.1421 0.5 28V8Z"
          fill="currentColor"/>
        <path
          d="M27.4659 10.7843C27.8877 11.2062 28.1247 11.7784 28.1247 12.375C28.1247 12.9716 27.8877 13.5438 27.4659 13.9658L16.2159 25.2158C15.794 25.6376 15.2218 25.8745 14.6252 25.8745C14.0286 25.8745 13.4564 25.6376 13.0344 25.2158L8.53443 20.7158C8.12458 20.2914 7.89779 19.723 7.90292 19.1331C7.90804 18.5432 8.14467 17.9788 8.56184 17.5617C8.97901 17.1445 9.54334 16.9079 10.1333 16.9027C10.7232 16.8976 11.2916 17.1244 11.7159 17.5343L14.6252 20.4435L24.2844 10.7843C24.7064 10.3624 25.2786 10.1255 25.8752 10.1255C26.4718 10.1255 27.044 10.3624 27.4659 10.7843Z"
          fill="white"/>
        <path
          d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H28C32.1421 0.5 35.5 3.85786 35.5 8V28C35.5 32.1421 32.1421 35.5 28 35.5H8C3.85786 35.5 0.5 32.1421 0.5 28V8Z"
          stroke="currentColor"/>
      </svg>

      <svg
        v-if="!$theme.isHexagon"
        :style="{height: props.size === 'md' ? '36px' : '18px' }"
        class="unchecked mr-2"
        fill="none"
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 8C1 4.13401 4.13401 1 8 1H28C31.866 1 35 4.13401 35 8V28C35 31.866 31.866 35 28 35H8C4.13401 35 1 31.866 1 28V8Z"
          fill="white"/>
        <path
          d="M1 8C1 4.13401 4.13401 1 8 1H28C31.866 1 35 4.13401 35 8V28C35 31.866 31.866 35 28 35H8C4.13401 35 1 31.866 1 28V8Z"
          stroke="#D0D0D0"
          stroke-width="2"/>
      </svg>


      <slot></slot>
    </label>

    <span v-if="errorMessage" class="text-sm text-watermelon-600">
      {{ errorMessage }}
    </span>
  </span>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate'
import { computed, getCurrentInstance, toRefs } from 'vue'

interface Props {
  checkedValue?: boolean | unknown
  disabled?: boolean
  errorMessageLabel?: string
  modelValue?: boolean | unknown | unknown[]
  name?: string
  rules?: string | Record<string, unknown | unknown[]>
  size?: 'sm' | 'md'
  standalone?: boolean
  uncheckedValue?: boolean | unknown
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md'
})
defineEmits(['update:modelValue'])
const { name, uncheckedValue, checkedValue } = toRefs(props)
const inputName = computed<string>(() => name?.value || 'checkbox-' + getCurrentInstance()?.uid)
const { handleChange, errorMessage, checked } = useField(inputName, props.rules, {
  type: 'checkbox',
  checkedValue: checkedValue,
  uncheckedValue: uncheckedValue,
  label: props.errorMessageLabel || inputName,
  standalone: props.standalone,
  initialValue: undefined // https://github.com/logaretm/vee-validate/issues/4443
})
</script>

<style scoped>
input[type=checkbox]:checked ~ svg.unchecked {
  display: none
}

input[type=checkbox]:not(:checked) ~ svg.checked {
  display: none
}
</style>
