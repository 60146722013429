<template>
  <div
    :class="{ 'duration-[250ms] delay-[50ms]': (delay > 0), 'duration-[100ms]': (delay == 0)}"
    :style="opacityStyle"
    class="dot-flashing transition-opacity"></div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'

interface Props {
  delay?: number
}

const props = withDefaults(defineProps<Props>(), {
  delay: 0
})

const opacity = ref(0)
const opacityStyle = computed<Partial<CSSStyleDeclaration>>(() => {
  return { opacity: opacity.value.toString() }
})

onMounted(() => {
  setTimeout(() => {
    opacity.value = 1
  }, props.delay)
})

</script>

<style scoped>
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #717171;
  color: #717171;
  animation: dotFlashing 0.5s infinite linear alternate;
  animation-delay: .25s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #717171;
  color: #717171;
  animation: dotFlashing 0.5s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #717171;
  color: #717171;
  animation: dotFlashing 0.5s infinite alternate;
  animation-delay: 0.5s;
}

@keyframes dotFlashing {
  0% {
    background-color: #717171;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
</style>
