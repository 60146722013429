<template>
  <th
    :class="{ 'py-2 px-2': !sortable }"
    class="text-left text-md font-semibold hexagon:font-medium text-black tracking-wider whitespace-nowrap"
    scope="col">
    <ColumnSorting v-if="sortable" :sort-by="sortBy" class="py-2.5 px-4">
      <slot/>
    </ColumnSorting>
    <slot v-else/>
  </th>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ColumnSorting from './column-sorting.vue'

export default defineComponent({
  name: 'TableHeadCell',
  components: { ColumnSorting },
  props: {
    sortBy: String,
    sortable: Boolean
  }
})
</script>
