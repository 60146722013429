import { Router, RouteRecordRaw } from 'vue-router'
import NotFoundPage from '../components/404.vue'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'home',
        component: () => Promise.resolve(import('@hexagon/home/home.vue')),
        meta: {
            auth: true
        }
    },
    {
        path: '/intro',
        name: 'intro',
        component: () => Promise.resolve(import('@hexagon/home/intro.vue')),
        meta: {
            auth: 'guest'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => Promise.resolve(import('@hexagon/auth/register.vue')),
        meta: {
            auth: 'guest'
        }
    },
    {
        path: '/login-redirect',
        name: 'login-redirect',
        component: () =>
            Promise.resolve(import('@hexagon/auth/login-redirect.vue')),
        meta: {
            auth: 'guest'
        }
    },
    {
        path: '/position/:positionId/calculation-result/:calculationId',
        name: 'exchangers',
        component: () =>
            Promise.resolve(import('@hexagon/exchangers/exchangers.vue')),
        meta: {
            auth: true
        }
    },
    {
        path: '/position/:positionId/exchanger/:calculationResultId',
        name: 'exchanger',
        component: () =>
            Promise.resolve(import('@hexagon/exchangers/exchanger.vue')),
        meta: {
            auth: true
        }
    },
    {
        path: '/position/:positionId/configuration',
        name: 'configuration',
        component: () =>
            Promise.resolve(import('@hexagon/configuration/configuration.vue')),
        meta: {
            auth: true
        }
    },
    {
        path: '/position/:positionId/unrealistic-configuration',
        name: 'unrealistic-configuration',
        component: () =>
            Promise.resolve(
                import('@hexagon/configuration/unrealistic-configuration.vue')
            ),
        meta: {
            auth: true
        }
    },
    {
        path: '/customer-edit/:id?',
        name: 'customer-edit',
        component: () =>
            Promise.resolve(import('@hexagon/offer/customer-edit.vue')),
        meta: {
            auth: true
        }
    },
    {
        path: '/project/:id',
        name: 'project-detail',
        component: () =>
            Promise.resolve(import('@hexagon/projects/project-detail.vue')),
        meta: {
            auth: true
        }
    },
    {
        path: '/projects',
        name: 'projects',
        component: () =>
            Promise.resolve(import('@hexagon/projects/projects.vue')),
        meta: {
            auth: true
        }
    },
    {
        path: '/position/:positionId/exchanger/:calculationResultId/accessories',
        name: 'exchanger-accessories',
        component: () =>
            Promise.resolve(
                import('@hexagon/exchangers/exchanger-accessory.vue')
            ),
        meta: {
            auth: true
        }
    },
    {
        path: '/:pathMath(.*)*',
        component: NotFoundPage
    }
]

// components overview only available in dev env
if (import.meta.env.DEV) {
    routes.push({
        path: '/_storybook',
        component: () => Promise.resolve(import('@hexagon/home/_storybook.vue'))
    })
}

// all routes which are called with additional params or query information should be placed inside here
function registerRouterMethods(router: Router) {
    router.resolveIntro = function () {
        return router.resolve({ name: 'intro' })
    }
    router.resolveRegister = function () {
        return router.resolve({ name: 'register' })
    }
    router.resolveHome = function () {
        return router.resolve({ name: 'home' })
    }
    router.replaceHome = function () {
        return router.replace({ name: 'home' })
    }
    router.pushHome = function () {
        return router.push({ name: 'home' })
    }
    router.resolveConfiguration = function (
        positionId: string,
        calculationId?: string
    ) {
        return router.resolve({
            name: 'configuration',
            params: { positionId },
            query: { 'calculation-id': calculationId }
        })
    }
    router.pushConfiguration = function (
        positionId: string,
        calculationId?: string
    ) {
        return router.push(
            router.resolveConfiguration(positionId, calculationId)
        )
    }
    router.pushUnrealisticConfiguration = function (positionId: string) {
        return router.push({
            name: 'unrealistic-configuration',
            params: { positionId }
        })
    }
    router.pushCalculationResult = function (
        positionId: string,
        calculationId: string
    ) {
        return router.push({
            name: 'exchangers',
            params: { positionId, calculationId }
        })
    }
    router.resolveCalculationResult = function (
        positionId: string,
        calculationId: string
    ) {
        return router.resolve({
            name: 'exchangers',
            params: { positionId, calculationId }
        })
    }
    router.pushExchanger = function (
        positionId: string,
        calculationResultId: string
    ) {
        return router.push({
            name: 'exchanger',
            params: { positionId, calculationResultId }
        })
    }
    router.resolveExchanger = function (
        positionId: string,
        calculationResultId: string
    ) {
        return router.resolve({
            name: 'exchanger',
            params: { positionId, calculationResultId }
        })
    }
    router.pushEditCustomer = function (customerId: string, projectId: string) {
        return router.push({
            name: 'customer-edit',
            params: { id: customerId },
            query: { 'project-id': projectId }
        })
    }
    router.pushNewCustomer = function (projectId: string) {
        return router.push({
            name: 'customer-edit',
            query: { 'project-id': projectId }
        })
    }
    router.pushProjects = function (events?: {
        requestedProjectWasDeleted?: boolean
        requestedProjectWasNotFound?: boolean
    }) {
        let query = {}

        if (events?.requestedProjectWasDeleted) {
            query = { ...query, 'project-deleted': true }
        }

        if (events?.requestedProjectWasNotFound) {
            query = { ...query, 'project-not-found': true }
        }

        return router.push({ name: 'projects', query })
    }
    router.resolveProjectDetail = function (projectId: string) {
        return router.resolve({
            name: 'project-detail',
            params: { id: projectId }
        })
    }
    router.pushProjectDetail = function (
        projectId: string,
        customerId?: string
    ) {
        return router.push({
            name: 'project-detail',
            params: { id: projectId },
            query: { 'customer-id': customerId }
        })
    }
    router.pushExchangerAccessories = function (
        positionId: string,
        calculationResultId: string
    ) {
        return router.push({
            name: 'exchanger-accessories',
            params: { positionId, calculationResultId }
        })
    }
}

export default routes
export { registerRouterMethods }
