<template>
  <span :class="getClasses()">
    <slot></slot>
  </span>
</template>
<script lang="ts" setup>

interface Props {
  hoverable?: boolean,
  invertApv?: boolean,
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hoverable: false,
  invertApv: false,
  disabled: false
})

function getClasses() {
  if(props.disabled) {
    return ['text-grey-600']
  }
  else {
    const classes = ['text-blue', 'btp:text-btp-blue']

    classes.push(props.invertApv ? 'apv:text-apv-green' : 'apv:text-apv-dark-green')

    if (props.hoverable) {
      classes.push('hover:text-dark-blue btp:hover:text-btp-dark-blue cursor-pointer')
      classes.push(props.invertApv ? 'apv:hover:text-apv-dark-green' : 'apv:hover:text-apv-green')
    }

    return classes
  }
}
</script>
