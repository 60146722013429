import { StorageSerializers, useStorage } from '@vueuse/core'
import { UserWithPermissionsModel } from '@hexagon/auth/models/user-with-permissions-model'

interface GuestUser {
  firstName: string
  lastName: string
  companyName: string
  companyStreet: string
  companyHouseNumber: string
  companyZip: string
  companyCity: string
  companyCountry: string
  email: string
}

const guestUser = useStorage<GuestUser | null>('guest-user', null, undefined, { serializer: StorageSerializers.object })

function saveGuestUser(newGuestUser: GuestUser) {
  guestUser.value = newGuestUser
}

function deleteGuestUser() {
  guestUser.value = null
}

function isGuestUser() {
  return guestUser.value
}

function getGuestUserAsModel(): UserWithPermissionsModel | null {
  return isGuestUser() ? {
    id: 'guest',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    email: guestUser.value!.email,
    name: guestUser.value?.firstName + ' ' + guestUser.value?.lastName,
    permissions: {}
  } : null
}

export function useGuestUser() {
  return {
    guestUser,
    saveGuestUser,
    deleteGuestUser,
    isGuestUser,
    getGuestUserAsModel
  }
}