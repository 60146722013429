// heavliy inspired by https://github.com/killmenot/vue3-vt-notifications

import { Plugin } from 'vue'
import NotificationGroup from '../components/notification-group.vue'
import Notifications from '../components/notifications.vue'

let installed = false

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    NotificationGroup: typeof NotificationGroup
    Notifications: typeof Notifications
  }
}

export const notifications: Plugin = {
  install(app) {
    if (!installed) {
      app.component('notifications', Notifications)
      app.component('notificationGroup', NotificationGroup)
      installed = true
    }
  }
}
