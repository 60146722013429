<template>
  <div class="w-full h-[10px] rounded-[5px] bg-[#e8e8e8]">
    <div class="h-[10px] rounded-[5px] bg-primary" :style="{'width': (progressPercent * 100) + '%'}"></div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  value: number
  min?: number
  max?: number
}

const props = withDefaults(defineProps<Props>(), {
  min: 0,
  max: 1
})

const progressPercent = computed<number>(() => {
  return props.value / (props.max - props.min)
})
</script>
