<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    group: {
      type: String,
      default: ''
    },
    position: {
      type: String as PropType<'top' | 'bottom'>,
      default: 'top',
      validator(value: string) {
        return ['top', 'bottom'].includes(value)
      }
    }
  },
  provide() {
    return {
      notificationContext: { group: this.group, position: this.position }
    }
  },
  render() {
    return this.$slots.default?.({
      group: this.group
    })
  }
})
</script>