import { useNotifications } from '@hexagon/notifications/use/useNotifications'
import { useStorage } from '@vueuse/core'
import { App, nextTick, Plugin } from 'vue'
import { Composer, createI18n as createVueI18n } from 'vue-i18n'
import { supportedLanguageCodes } from '@hexagon/common/components/i18n/supported-languages'

declare module 'vue-i18n' {
    interface Composer {
        setLocale: (locale: string) => Promise<void>
    }
}

export function createI18n(overrideLocale?: string): Plugin & Composer {
    const defaultLocale = 'en'
    const selectedLocale = useStorage(
        'locale',
        window.navigator.language.split('-')[0]
    )
    if (overrideLocale) {
        selectedLocale.value = overrideLocale
    }
    if (!supportedLanguageCodes.includes(selectedLocale.value)) {
        selectedLocale.value = defaultLocale
    }
    const vueI18n = createVueI18n<false>({
        legacy: false,
        locale: selectedLocale.value,
        // available locales available at: https://l10n.deckweiss.at/api/projects/hexagon/languages/
        fallbackLocale: defaultLocale,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        missing: () => {}
    })

    vueI18n.global.setLocale = async function (locale: string) {
        const isLocaleAlreadyLoaded: boolean =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            vueI18n.global.messages.value[locale] &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Object.keys(vueI18n.global.messages.value[locale]).length > 0

        if (!isLocaleAlreadyLoaded) {
            await loadTranslations(locale)
            await nextTick()
        }

        selectedLocale.value = locale
        vueI18n.global.locale.value = locale
        document.querySelector('html')?.setAttribute('lang', locale)
    }

    vueI18n.global.setLocale(selectedLocale.value)
    if (selectedLocale.value !== defaultLocale) {
        loadTranslations(defaultLocale)
    }

    async function loadTranslations(locale: string) {
        let messagesResponse
        if (import.meta.env.VITE_ENV) {
            messagesResponse = await import(`../locales/${locale}.json`)
        } else {
            messagesResponse = await fetch(
                `https://l10n.deckweiss.at/api/translations/hexagon/web/${locale}/file/`
            ).then((res) => res.json())
        }

        if (messagesResponse) {
            vueI18n.global.setLocaleMessage(locale, messagesResponse)
        } else {
            useNotifications().error(
                `Couldn't load translations for language '${locale}'`
            )
        }
    }

    return {
        ...vueI18n.global,
        install: (app: App) => {
            app.use(vueI18n)
        }
    }
}
