<template>
  <div :class="[isInline ? 'items-center space-x-1' : 'flex-col space-y-1']" class="relative flex">
    <slot name="label">
      <label v-if="label" :for="inputName" class="text-sm font-semibold hexagon:font-medium text-grey-1000" v-text="label"></label>
    </slot>
    <div class="flex flex-col w-full">
      <div class="relative">
        <component
          :is="tag"
          :id="inputName"
          :class="[inputClass, disabled ? 'bg-layer1 text-grey-600' : '', isInline ? 'py-1' : '', compact ? 'h-[2rem] text-sm' : 'text-md', !disableMinWidth ? 'min-w-[100px]' : '', errorMessage ? 'border-watermelon-600' : 'border-grey-300']"
          :disabled="disabled"
          :lang="$i18n.locale"
          :placeholder="loading ? $t('ui.input.loading') : placeholder"
          :tabindex="tabindex"
          :type="typeRef"
          :value="value"
          class="block w-full px-3 py-2 hexagon:border-layer2 shadow shadow-transparent hexagon:shadow-black/10 rounded-md text-grey-1000 font-normal placeholder:text-grey-600 focus:ring-0 focus:border-transparent focus:shadow-[0_0_0_3px_rgba(0,0,0,0)] focus:shadow-primary-500 hexagon:focus:shadow-blue apv:focus:shadow-apv-dark-green/50"
          v-bind="$attrs"
          @blur="onBlur"
          @input="handleChange"/>

        <div v-if="slots.prefix" :id="prefixId" class="text-grey-700 leading-6 font-normal absolute top-[50%] -translate-y-2/4 text-sm left-3">
          <slot name="prefix"/>
        </div>

        <div v-if="slots.suffix || type === 'password'" :id="suffixId" class="text-grey-700 pl-2 leading-6 font-normal absolute top-[50%] -translate-y-2/4 text-sm right-0">
          <button v-if="type === 'password'" class="h-full px-4" @click="toggleShowPassword()">
            <dw-show-password-icon v-if="showPassword"/>
            <dw-hide-password-icon v-else/>
          </button>
          <slot name="suffix"/>
        </div>

        <div :id="suffixId" class="absolute right-0 top-0 bottom-0 flex items-center">
          
        </div>
      </div>
      <span v-if="error && !hideError" class="text-sm text-watermelon-600">{{ error }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate'
import { computed, getCurrentInstance, onMounted, ref, toRefs, useSlots } from 'vue'

interface Props {
  compact?: boolean
  component?: boolean
  disableMinWidth?: boolean
  disabled?: boolean
  errorMessage?: string
  errorMessageLabel?: string
  hideError?: boolean
  inputClass?: string
  isInline?: boolean
  label?: string
  loading?: boolean
  modelValue?: unknown
  name?: string
  placeholder?: string
  standalone?: boolean
  tabindex?: number
  type?: string
}

const emits = defineEmits(['blur', 'update:modelValue'])
const props = withDefaults(defineProps<Props>(), { 
  type: 'text',
  enableMinWidth: false
 })

const { name: nameProp, errorMessageLabel: errorMessageLabelProp } = toRefs(props)
const inputName = computed<string>(() => nameProp?.value || 'input-' + getCurrentInstance()?.uid)
const { value, handleChange, handleBlur, errorMessage: validationErrorMessage, setValue } = useField(inputName, undefined, {
  label: errorMessageLabelProp,
  standalone: props.standalone
})

const tag = computed(() => props.type === 'textarea' ? 'textarea' : 'input')
const showPassword = ref(false)
const typeRef = computed(() => props.type === 'password' && showPassword.value ? 'text' : props.type)
const error = computed(() => props.errorMessage || validationErrorMessage.value)

function onBlur(event: Event & { target: EventTarget & { value: string } }) {
  handleBlur(event)
  emits('blur', event.target?.value)
}

function toggleShowPassword() {
  showPassword.value = !showPassword.value
}

defineExpose({ setValue })


const instanceId = getCurrentInstance()?.uid
const suffixId = `dw-input-suffix_${instanceId}`
const prefixId = `dw-input-prefix_${instanceId}`
const slots = useSlots()

onMounted(() => {
  const inputElement = document.getElementById(inputName.value)
  if (inputElement) {
    if (slots.prefix) {

      const prefixElement = document.getElementById(prefixId)
      if(prefixElement) {
        inputElement.style.paddingLeft = `${20 + prefixElement.offsetWidth}px`
      }
    }
    if(slots.suffix || typeRef.value === 'password') {
      const suffixElement = document.getElementById(suffixId)
      if(suffixElement) {
        inputElement.style.paddingRight = `${-20 + suffixElement.offsetWidth}px`
      }
    }
  }
})

</script>
