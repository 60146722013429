import { RouteMeta } from 'vue-router'
import { UserWithPermissionsModel } from '../models/user-with-permissions-model'

export function checkIfRoutingCanBeDone(route: RouteMeta, user: UserWithPermissionsModel | null): boolean {
  if (!route.auth
      || (route.auth === true && user !== null)
      || (route.auth === 'guest' && user === null)
      || (Array.isArray(route.auth) && route.auth.filter(permissionAccessor => user?.permissions[permissionAccessor]).length >= 1)
  ) {
    return true
  }

  return false
}